<template>
	<div class="index-container">
	    <!-- 用户统计 -->
	    <el-card class="">
	    	<h4 class="font-lg font-weight-normal m-0 mb-3 text-black">用户数据</h4>
	    	<el-row :gutter="20" type="flex">
	    		<el-col class="text-center status-item">
	    			<span class="mb-2 d-inline-block opacity-60">会员总数</span>
	    			<p class="m-0 fs_24">{{information.user_num || 0}}</p>
	    		</el-col>
	    		<el-col class="text-center status-item">
	    			<span class="mb-2 d-inline-block opacity-60">律师总数</span>
	    			<p class="m-0 fs_24">{{information.ls_num || 0}}</p>
	    		</el-col>
	    		<el-col class="text-center status-item">
	    			<span class="mb-2 d-inline-block opacity-60">子账号数</span>
	    			<p class="m-0 fs_24">{{information.child_user_num || 0}}</p>
	    		</el-col>
	    		<el-col class="text-center status-item">
	    			<span class="mb-2 d-inline-block opacity-60">活动总数</span>
	    			<p class="m-0 fs_24">{{information.activity_num || 0}}</p>
	    		</el-col>
	    		<el-col class="text-center status-item">
	    			<span class="mb-2 d-inline-block opacity-60">问卷总数</span>
	    			<p class="m-0 fs_24">{{information.wj_num || 0}}</p>
	    		</el-col>
	    		<el-col class="text-center status-item">
	    			<span class="mb-2 d-inline-block opacity-60">缴费记录</span>
	    			<p class="m-0 fs_24">{{information.jfjl_num || 0}}</p>
	    		</el-col>
	    	</el-row>
	    </el-card>
		
		<!-- 项目汇总--状态统计 -->
		<el-card class="mt-3">
			<h4 class="font-lg font-weight-normal m-0 mb-3 text-black">资源汇总</h4>
			<el-row :gutter="20" type="flex">
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">协会相册</span>
					<p class="m-0 fs_24">{{information.dzhk_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">协会视频</span>
					<p class="m-0 fs_24">{{information.xhsp_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">电子会刊</span>
					<p class="m-0 fs_24">{{information.dzhk_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">党建中心</span>
					<p class="m-0 fs_24">{{information.djzx_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">协会动态</span>
					<p class="m-0 fs_24">{{information.xhdt_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">协会公告</span>
					<p class="m-0 fs_24">{{information.notice_num || 0}}</p>
				</el-col>
			</el-row>
		</el-card>
		
		<!-- 会员统计 -->
		<el-card class="mt-3">
			<h4 class="font-lg font-weight-normal m-0 mb-3 text-black">会员统计</h4>
			<el-row :gutter="20" type="flex">
				<el-col class="text-center status-item" v-for="(item,index) in user_grade" :key="item.id" >
					<span class="mb-2 d-inline-block opacity-60">{{item.name || ''}}</span>
					<p class="m-0 fs_24 elementCursor" @click="toPathUser" >{{item.num || 0}}</p>
				</el-col>
			</el-row>
		</el-card>
		
		<!-- 缴费统计 -->
		<el-card class="mt-3">
			<h4 class="font-lg font-weight-normal m-0 mb-3 text-black">缴费统计</h4>
			<el-row :gutter="20" type="flex">
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">会员总数</span>
					<p class="m-0 fs_24" >{{user_jf.user_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">已缴会员</span>
					<p class="m-0 fs_24 elementCursor"@click="toPathJfjl('1')">{{user_jf.yj_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">未缴会员</span>
					<p class="m-0 fs_24 elementCursor"@click="toPathJfjl('2')">{{user_jf.wjf_num || 0}}</p>
				</el-col>
				<el-col class="text-center status-item">
					<span class="mb-2 d-inline-block opacity-60">申请开票</span>
					<p class="m-0 fs_24">{{user_jf.kp_num || 0}}</p>
				</el-col>
			</el-row>
		</el-card>
		
		<!-- <el-row :gutter="20" class="mt-3"> -->
			<!-- 系统日志 -->
<!-- 			<el-col :span="12">
				<el-card :body-style="{ padding: '0px' }">
					<div class="flex justify-between align-center isCell">
						<h4 class="font-lg font-weight-normal m-0 text-black">会员统计</h4>
						<div class="tab_nav"></div>
					</div>
					<div class="tab_content" style="padding: 20px">
                         <div ref="chart1" style="width: 100%;height: 300px;"></div>
					</div>
				</el-card>
			</el-col> -->
			
			<!-- 系统日志 -->
<!-- 			<el-col :span="12">
				<el-card :body-style="{ padding: '0px' }">
					<div class="flex justify-between align-center isCell">
						<h4 class="font-lg font-weight-normal m-0 text-black">缴费统计</h4>
						<div class="tab_nav"></div>
					</div>
					<div class="tab_content" style="padding: 20px">
	                    <div ref="chart2" style="width: 100%;height: 300px;"></div>
					</div>
				</el-card>
			</el-col>
		</el-row> -->
	</div>
</template>

<script>
import * as echarts from "echarts"
	export default {
		name: 'Index',
		data () {
			return {
				information:[],
				system_log:[],
				user_grade:[],
				user_jf:[],
				chart1: {
					  tooltip: {
					    trigger: 'item'
					  },
					  legend: {
					    orient: 'vertical',
					    left: 'left'
					  },
					  series: [
					    {
					      type: 'pie',
					      radius: ['40%', '70%'],
						  left: 100,
						  label: {
						          show: false,
						          position: 'center'
						  },
					      data: [
					        { value: 1048, name: 'Search Engine' },
					        { value: 735, name: 'Direct' },
					        { value: 580, name: 'Email' },
					        { value: 484, name: 'Union Ads' },
					        { value: 300, name: 'Video Ads' }
					      ],
					      emphasis: {
					        label: {
							  show: true,
							  fontSize: 40,
							  fontWeight: 'bold'
							}
					      }
					    }
					]
				},
				chart2: {
					  tooltip: {
					    trigger: 'item'
					  },
					  legend: {
					    orient: 'vertical',
					    left: 'left'
					  },
					  series: [
					    {
					      type: 'pie',
					      radius: ['40%', '70%'],
						  left: 100,
						  label: {
						          show: false,
						          position: 'center'
						  },
					      data: [
					        { value: 1048, name: 'Search Engine' },
					        { value: 735, name: 'Direct' },
					        { value: 580, name: 'Email' },
					        { value: 484, name: 'Union Ads' },
					        { value: 300, name: 'Video Ads' }
					      ],
					      emphasis: {
					        label: {
							  show: true,
							  fontSize: 40,
							  fontWeight: 'bold'
							}
					      }
					    }
					]
				},
			}
		},

		mounted(){
			this.getInformation()
			this.systemLog()
			this.userTypeStatistics()
			this.userJfStatistics()
			// this.userStatistics()
			// this.jfStatistics()
		},
		methods:{
			toPathUser(){
				this.$router.push({path:'/user'})
			},
			toPathJfjl(param){
				this.$router.push({ path: '/jfjl', query: { action: param } });
			},
			getInformation(){
			   this.axios.get('/manage/information').then(res=>{
				 this.information = res.data
			   })
			},
			userTypeStatistics(){
				this.axios.get('/manage/userTypeStatistics').then(res=>{
				   if(res.status){
					   this.user_grade = res.data
				   }
				})
			},
			userJfStatistics(){
				this.axios.get('/manage/userJfStatistics').then(res=>{
				   if(res.status){
					  this.user_jf = res.data
				   }
				})
			},
			userStatistics(){
			   this.axios.get('/manage/userStatistics').then(res=>{
				   if(res.status){
					   if(res.data.length > 0){
						   this.chart1.series[0].data = res.data
						   // 画统计图
						   this.$nextTick(()=>{
						     this.drawLine1()
						   })
					   }
				   }
			   })
			},
			jfStatistics(){
				this.axios.get('/manage/jfStatistics').then(res=>{
				   if(res.status){
					   if(res.data.length > 0){
						   this.chart2.series[0].data = res.data
						   // 画统计图
						   this.$nextTick(()=>{
							 this.drawLine2()
						   })
					   }
				   }
				})
			},
			systemLog(){
				this.axios.get('/manage/operation_log/list',{
					params:{
						page:1,
						limit:10
					}
				}).then(res=>{
					this.system_log = res.data
				})
			},
		    drawLine1() {
		         // 初始化echarts实例
		         let myChart = echarts.init(this.$refs.chart1)
		         // 配置参数
		         myChart.setOption(this.chart1)
		    },
			drawLine2() {
			     // 初始化echarts实例
			     let myChart = echarts.init(this.$refs.chart2)
			     // 配置参数
			     myChart.setOption(this.chart2)
			},
		},
}
</script>

<style>
.elementCursor:hover{
	cursor: pointer;
}
</style>